import Carousel from 'nuka-carousel';
import Fade from 'react-reveal/Fade';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import PropTypes from 'prop-types';
import QuoteIcon from '../img/quote-icon.svg';
import React from 'react';
import styled from 'styled-components';

class Quotes extends React.Component {
  state = {
    slideIndex: 0,
    slideWidth: 0.5,
  };

  handleWidthChange = (e) => {
    if (e.matches) {
      this.setState({ slideWidth: 0.5 });
    } else {
      this.setState({ slideWidth: 1 });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    if (window.innerWidth <= 769) {
      this.setState({ slideWidth: 1 });
    } else {
      this.setState({ slideWidth: 0.5 });
    }
  }

  render() {
    return (
      <Carousel
        style={{ outline: 'none' }}
        slideWidth={this.state.slideWidth}
        slideHeight={'max'}
        cellSpacing={60}
        cellAlign={'center'}
        afterSlide={(slideIndex) => {
          this.setState({ slideIndex });
        }}
        enableKeyboardControls
        renderCenterLeftControls={({ previousSlide }) => <ChangeSlide onClick={previousSlide} />}
        renderCenterRightControls={({ nextSlide }) => <ChangeSlide onClick={nextSlide} />}
      >
        {this.props.quotes
          .sort((a, b) => a.order - b.order)
          .map((quote, index) => (
            <Slide key={index} currentSlide={this.props.currentSlide}>
              <Icon>
                <IconImage src={QuoteIcon} />
              </Icon>
              <Quote className="has-text-centered" currentPage={index === this.state.slideIndex}>
                {quote.quote}
              </Quote>
              <Fade big cascade when={index === this.state.slideIndex}>
                <Owner className="has-text-centered" key={quote.name}>
                  <OwnerImage>
                    <PreviewCompatibleImage imageInfo={quote} />
                  </OwnerImage>

                  <div>
                    <p
                      style={{ color: 'white' }}
                      className="has-text-weight-bold is-size-4-desktop is-size-6 name"
                    >
                      {quote.name}
                    </p>
                    <p className="position">{quote.title}</p>
                    <p className="position">{quote.company}</p>
                  </div>
                </Owner>
              </Fade>
            </Slide>
          ))}
      </Carousel>
    );
  }
}

Quotes.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      title: PropTypes.string,
      company: PropTypes.string,
      quote: PropTypes.string,
    }),
  ),
};

export default Quotes;

const Slide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:focus {
    outline: none !important;
  }
  outline: none;
`;

const Quote = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  height: auto;
  color: #fff;
  opacity: ${(props) => (props.currentPage ? '1' : '0.5')};
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 18px;

  @media screen and (min-width: 769px) {
    min-height: 200px;
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 28px;
  }

  @media screen and (min-width: 1216px) {
    font-size: 37px;
  }
`;

const ChangeSlide = styled.div`
  width: 100px;
  height: 300px;

  @media screen and (min-width: 769px) {
    width: 200px;
    height: 200px;
  }

  @media screen and (min-width: 1024px) {
    width: 400px;
    height: 400px;
  }
`;

const Owner = styled.div`
  margin-bottom: 50px;
  background-color: transparent;
`;

const Icon = styled.div`
  text-align: center;
`;

const IconImage = styled.img`
  width: 85px;
  height: 55px;

  @media screen and (min-width: 769px) {
    width: 95px;
    height: 65px;
  }

  @media screen and (min-width: 1024px) {
    width: 105px;
    height: 75px;
  }

  @media screen and (min-width: 1216px) {
    width: 115px;
    height: 85px;
  }

  @media screen and (min-width: 1408px) {
    width: 125px;
    height: 95px;
  }
`;

const OwnerImage = styled.div`
  width: 60px;
  height: 60px;
  margin: 0 auto;

  @media screen and (min-width: 769px) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 1024px) {
    width: 80px;
    height: 80px;
  }

  @media screen and (min-width: 1216px) {
    width: 90px;
    height: 90px;
  }

  @media screen and (min-width: 1408px) {
    width: 100px;
    height: 100px;
  }
`;
