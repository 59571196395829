import React from 'react';
import Icon from '../img/email-icon-mobile.svg';

const ContactUsIcon = () => {
  return (
    <a href="/contact-us">
      <img src={Icon} alt="ContactUs" className="level-item is-hidden-tablet contact-us-icon" />
    </a>
  );
};
export default ContactUsIcon;
