import React from 'react';
import styled from 'styled-components';

const Border = () => {
  return <Div />;
};

export default Border;

const Div = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #251f26;
  z-index: 5;
`;
