import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Awards = ({ awards }) => (
  <Wrapper>
    {awards.map((award, index) =>
      award.url ? (
        <Link
          key={index}
          href={award.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ gridArea: `award${index + 1}` }}
        >
          <Award>
            <Logo src={award.image} />
            <NameContainer>
              <LaurelLeft src="/img/awards/laurel-left.svg" />
              <Name className="is-size-15-to-25">
                <>
                  {award.name1}
                  <br></br>
                  {award.name2}
                  <br></br>
                  {award.name3}
                </>
              </Name>
              <LaurelRight src="/img/awards/laurel-right.svg" />
            </NameContainer>
            <Year> {award.year} </Year>
          </Award>
        </Link>
      ) : (
        <Award key={index} style={{ gridArea: `award${index + 1}` }}>
          <Logo src={award.image} alt={award.name} />
          <NameContainer>
            <LaurelLeft src="/img/awards/laurel-left.svg" />
            <Name className="is-size-15-to-25">
              <>
                {award.name1}
                <br></br>
                {award.name2}
                <br></br>
                {award.name3}
              </>
            </Name>
            <LaurelRight src="/img/awards/laurel-right.svg" />
          </NameContainer>
          <Year> {award.year} </Year>
        </Award>
      ),
    )}
  </Wrapper>
);

Awards.propTypes = {
  awards: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name1: PropTypes.string,
      name2: PropTypes.string,
      name3: PropTypes.string,
      year: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

export default Awards;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 2.5rem;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-areas:
      'award1 award1 award2 award2 award3 award3 award4 award4'
      '. award5 award5 award6 award6 award7 award7 .';
    column-gap: 7.5rem;
    row-gap: 5rem;
    justify-content: center;
  }
`;

const Award = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  justify-self: center;
  object-fit: contain;
  width: 90px;
  height: 90px;

  @media screen and (min-width: 1024px) {
    width: auto;
    height: auto;
  }
`;
const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin-bottom: 0.5rem;
`;
const Laurel = styled.img`
  height: 100%;
  margin-top: 0rem;
  position: absolute;

  @media screen and (min-width: 1024px) {
    height: 105%;
  }
`;
const LaurelLeft = styled(Laurel)`
  left: -1rem;

  @media screen and (min-width: 1024px) {
    left: -1.75rem;
  }
`;
const LaurelRight = styled(Laurel)`
  right: -1rem;

  @media screen and (min-width: 1024px) {
    right: -1.75rem;
  }
`;
const Name = styled.h3`
  color: rgb(198, 153, 68);
  font-family: 'Khand-SemiBold';
  text-transform: uppercase;
  letter-spacing: 0.6px;
  text-align: center;
  font-size: 28px;
  line-height: 26px;
  background: linear-gradient(-180deg, rgba(255, 255, 255, 0.31) 0%, rgba(0, 0, 0, 0.31) 100%)
    rgb(198, 153, 68);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Year = styled.span`
  color: #585765;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.44px;
  text-align: center;
`;

const Link = styled.a`
  text-align: center;
`;
