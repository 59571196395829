import Carousel from 'nuka-carousel';
import React from 'react';
import styled from 'styled-components';

const LogosCarousel = () => {
  return (
    <Div id="filmchain-newspapers">
      <div className="has-text-centered columns is-multiline is-centered">
        <div className="column is-full has-text-centered">
          {/* <p>AS SEEN IN</p> */}
          <CenteredDiv>
            <img
              src="img/PR-title.svg"
              alt="AS SEEN IN"
              style={{ width: '115px', margin: '0 auto', objectFit: 'cover' }}
            />
          </CenteredDiv>
        </div>

        <div className="column is-mobile is-three-quarters">
          {/* <Carousel defaultWait={1000} */}
          <Carousel
            dragging={false}
            autoplay
            autoplayInterval={4000}
            pauseOnHover={false}
            wrapAround={true}
            withoutControls={true}
          >
            <div className="columns is-mobile is-centered is-vcentered is-multiline">
              {/*The Guardian*/}
              <div className="column is-one-third-mobile is-2-tablet">
                <a
                  href="https://www.theguardian.com/careers/2020/may/26/tackling-the-dark-side-of-the-movie-business-the-blockchain-startup-ensuring-film-workers-get-paid"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="svg-icon hero-footer--icon">
                    <img src={'img/pr-icons/guardian-logo.svg'} alt="The Guardian" />
                  </span>
                </a>
              </div>
              {/*Cineuropa*/}
              <div className="column is-one-third-mobile is-2-tablet">
                <a
                  href=" https://cineuropa.org/en/newsdetail/384431/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="svg-icon hero-footer--icon">
                    <img src={'img/pr-icons/cineuropa.svg'} alt="Cineuropa" />
                  </span>
                </a>
              </div>
              {/*Forbes*/}
              <div className="column is-one-third-mobile is-2-tablet">
                <a
                  href="https://www.forbes.com/sites/nelsongranados/2018/12/03/how-blockchain-is-making-waves-in-media-and-entertainment/?fbclid=IwAR2DHNJyRfsRsf8-WDQpkET37Ia7-A6S-QKfZAz-BMTEEr_6VIPkVgnJlpY#1803c44c3f6c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="svg-icon hero-footer--icon">
                    <img src={'img/pr-icons/forbes.svg'} alt="Forbes" />
                  </span>
                </a>
              </div>
              {/*ScreenDaily*/}
              <div className="column is-one-third-mobile is-2-tablet">
                <a
                  href="https://www.screendaily.com/features/will-blockchain-become-a-game-changer-for-the-film-industry/5129044.article"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="svg-icon hero-footer--icon">
                    <img src={'img/pr-icons/screen-daily.svg'} alt="SCREENDAILY" />
                  </span>
                </a>
              </div>
              {/*Feed*/}
              <div className="column is-one-third-mobile is-2-tablet">
                <a
                  href="https://feedmagazine.tv/index.php/start-up-alley/start-up-big-couch-united-kingdom-2014/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="hero-footer--icon">
                    <img src={'img/pr-icons/feed.png'} alt="FEED" />
                  </span>
                </a>
              </div>
              {/*Variety*/}
              <div className="column is-one-third-mobile is-2-tablet">
                <a
                  href="https://variety.com/2020/film/festivals/san-sebastian-zinemaldia-startup-challenge-filmchain-1234783626/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="svg-icon hero-footer--icon">
                    <img src={'img/pr-icons/variety.svg'} alt="nofilmschool" />
                  </span>
                </a>
              </div>
            </div>

            <div className="columns is-mobile is-centered is-vcentered is-multiline">
              {/*SXSWL*/}
              <div className="column is-one-third-mobile is-2-tablet">
                <a
                  href="https://www.sxsw.com/interactive/2020/sxsw-pitch-2020-finalists-announcement/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="svg-icon hero-footer--icon">
                    <img src={'img/pr-icons/sxsw-logo.svg'} alt="SXSW" />
                  </span>
                </a>
              </div>
              {/*AsiaTimes*/}
              <div className="column is-one-third-mobile is-2-tablet">
                <a
                  href="https://www.asiatimes.com/2018/05/article/at-this-years-cannes-the-buzzword-is-blockchain/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="hero-footer--icon">
                    <img src={'img/pr-icons/atimes.png'} alt="SCREENDAILY" />
                  </span>
                </a>
              </div>
              {/*Cineuropa*/}
              <div className="column is-one-third-mobile is-2-tablet">
                <a
                  href="https://cineuropa.org/en/newsdetail/370612"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="svg-icon hero-footer--icon">
                    <img src={'img/pr-icons/cineuropa.svg'} alt="Cineuropa" />
                  </span>
                </a>
              </div>
              {/*IBC*/}
              <div className="column is-one-third-mobile is-narrow-tablet">
                <a
                  href="https://www.ibc.org/tech-advances/putting-blockchain-to-work-in-media/3136.article"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="svg-icon hero-footer--icon">
                    <img src={'img/pr-icons/ibc-logo.svg'} alt="Cineuropa" />
                  </span>
                </a>
              </div>
              {/*NoFilmSchool*/}
              <div className="column is-one-third-mobile is-2-tablet">
                <a
                  href="https://nofilmschool.com/2018/02/why-blockchain-will-change-how-business-done-film-industry"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="hero-footer--icon">
                    <img src={'img/pr-icons/no-film-school.png'} alt="nofilmschool" />
                  </span>
                </a>
              </div>
              {/*Maddyness*/}
              <div className="column is-one-third-mobile is-2-tablet">
                <a
                  href="https://www.maddyness.com/uk/2020/05/15/covid-19-interview-with-irina-and-maria-co-founders-of-filmchain/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="svg-icon hero-footer--icon">
                    <img src={'img/pr-icons/maddyness-logo.svg'} alt="Maddyness" />
                  </span>
                </a>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </Div>
  );
};

export default LogosCarousel;

const Div = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* justify-content: space-between; */
  position: relative;
  /* z-index: 1; */
  background-color: #110d12;
  padding-top: 40px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 80px;
  @media screen and (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 20px;
    padding-left: 20px;
  }
`;
const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;
