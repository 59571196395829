import React from 'react';
import styled from 'styled-components';

const ContactUs = () => {
  return (
    <Btn
      className={`is-hidden-mobile level-item contact-us btn`}
      href="/contact-us"
      rel="noopener noreferrer"
    >
      Want to know more? Contact us
    </Btn>
  );
};
export default ContactUs;

const Btn = styled.a`
  z-index: 9999;
  background-color: #110d12;
  justify-self: end;
  padding-right: 25px;
  margin-bottom: 25px;
  @media screen and (min-width: 769px) {
    padding-right: 80px;
  }
`;
