import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Nominations = ({ nominations, image }) => (
  <Wrapper>
    <FlexRow>
      {nominations.map((nomination, index) => (
        <Link key={index} href={nomination.url} target="_blank" rel="noopener noreferrer">
          <Grid>
            <img src={nomination.image} />
            <Description>
              {nomination.firstText}{' '}
              <span style={{ fontWeight: '600' }}>{nomination.boldText}</span> {nomination.lastText}
            </Description>
          </Grid>
        </Link>
      ))}
    </FlexRow>
  </Wrapper>
);

Nominations.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  nominations: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      firstText: PropTypes.string,
      boldText: PropTypes.string,
      lastText: PropTypes.string,
    }),
  ),
};

export default Nominations;

const Wrapper = styled.div`
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (min-width: 1200px) {
    justify-content: space-between;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 15px;
  width: 250px;
  margin: 20px 10px;
  text-align: center;
  justify-content: center;
  img {
    justify-self: center;
    height: 35px;
  }

  @media screen and (min-width: 769px) {
    grid-row-gap: 10px;
  }
`;

const Description = styled.div`
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
`;

const Link = styled.a`
  text-align: center;
`;
