import React from 'react';
import styled from 'styled-components';

export default props => (
    <SVG height={32} width={33} xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fill="none" fillRule="evenodd" transform="translate(.919 -.004)">
            <rect id="FC-design-system-atoms-icons" fill="#585765" height={32.004} rx={16} width={32} />
            <path
                d="M13.017 21.002v-8.178h-2.54v8.178zm-1.271-9.294c.812 0 1.47-.673 1.47-1.485a1.471 1.471 0 0 0-2.941 0c0 .812.659 1.485 1.47 1.485zm10.776 9.294h.003V16.51c0-2.198-.473-3.89-3.043-3.89-1.235 0-2.064.678-2.403 1.32h-.035v-1.115h-2.436v8.178h2.537v-4.05c0-1.066.202-2.097 1.523-2.097 1.3 0 1.32 1.217 1.32 2.166v3.981z"
                fill="#fff"
                fillRule="nonzero"
            />
        </g>
    </SVG>
);

const SVG = styled.svg`
  vertical-align: middle;
   #FC-design-system-atoms-icons {
    fill:  #585765
  }

  &:hover {
    #FC-design-system-atoms-icons {
        fill: #a7a6b3;
    }
  }
`